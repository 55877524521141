import React, { Component } from "react"
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBIcon,
  MDBAnimation,
} from "mdbreact"
import { navigate } from "gatsby-link"
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class Promo extends Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
    console.log(e.target.value)
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }
  render() {
    return (
      <div className="promo">
        <MDBContainer className="frontpromo">
          <MDBRow>
            <MDBCol md="7" sm="12">
              <MDBTypography tag="h1">Frischer Wind gefällig?</MDBTypography>
              <MDBTypography tag="h5">
                Videos, Websites, UX/UI Design, Corporate Identity
              </MDBTypography>
              <MDBTypography tag="p">
                Wir sind erfahren und dynamisch, wir sind strukturiert und
                innovativ, wir sind Designer und Strategen - und gemeinsam mit
                Ihnen wollen wir erstklassige Erlebnisse für Ihre Kunden
                schaffen.
              </MDBTypography>
              <MDBTypography listUnStyled>
                <li style={{ marginTop: "50px", marginBottom: "50px" }}>
                  Unsere Spezialgebiete:
                  <ul>
                    <li style={{ fontWeight: "300", paddingTop: "15px" }}>
                      + Web & Mobile App Development
                    </li>
                    <li style={{ fontWeight: "300" }}>+ Video und Animation</li>
                    <li style={{ fontWeight: "300" }}>+ UX-Beratung</li>
                    <li style={{ fontWeight: "300" }}>+ Corporate Identity</li>
                  </ul>
                </li>
              </MDBTypography>
            </MDBCol>
            <MDBCol md="5" sm="12">
              <div className="right">
                <h4>Lassen Sie sich jetzt kostenlos beraten!</h4>
                <div className="lan-form">
                  <form
                    name="ServiceRequest"
                    method="post"
                    action="/thank-you"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                  >
                    <input type="hidden" name="form-name" value="ServiceRequest" />
                    <label className="font-weight-light">Name*</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      onChange={this.handleChange}
                      required
                    />

                    <label className=" font-weight-light">E-Mail*</label>
                    <input
                      name="email"
                      type="email"
                      className="form-control"
                      onChange={this.handleChange}
                      required
                    />
                    <div className="checkbox">
                      <label
                        className="form-check-label"
                        style={{ marginBottom: "15px" }}
                      >
                        Für welchen Service interessieren Sie sich besonders?
                      </label>
                      <MDBRow>
                        <MDBCol>
                          <div>
                            <div class="form-check ">
                              <input
                                class="form-check-input"
                                name="CorporateIdentity"
                                type="checkbox"
                                onChange={this.handleChange}
                                id="inlineCheckbox1"
                                value="Corporate Identity"
                              />
                              <label
                                class="font-weight-light"
                                for="inlineCheckbox1"
                              >
                                Corporate Identity
                              </label>
                            </div>
                            <div class="form-check ">
                              <input
                                class="form-check-input"
                                name="WebAppDevelopment"
                                type="checkbox"
                                id="inlineCheckbox2"
                                onChange={this.handleChange}
                                value=" Web & App Development"
                              />
                              <label
                                class="font-weight-light"
                                for="inlineCheckbox2"
                              >
                                Web & App Development
                              </label>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol>
                          <div>
                            <div class="form-check ">
                              <input
                                name="VideoAnimation"
                                class="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                onChange={this.handleChange}
                                value="Video & Animation"
                              />
                              <label
                                class="font-weight-light"
                                for="inlineCheckbox1"
                              >
                                Video & Animation
                              </label>
                            </div>
                            <div class="form-check ">
                              <input
                                name="UXConsultancy"
                                class="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox2"
                                onChange={this.handleChange}
                                value="UX Consultancy"
                              />
                              <label
                                class="font-weight-light"
                                for="UX Consultancy"
                              >
                                UX Consultancy
                              </label>
                            </div>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </div>

                    <label className="font-weight-light">Nachricht</label>
                    <textarea
                      name="message"
                      type="text"
                      className="form-control"
                      rows="3"
                      onChange={this.handleChange}
                    />
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        required
                      />
                      <label className="form-check-label">
                        Ich habe die{" "}
                        <a
                          href="https://vicreation.de/de/data-privacy/"
                          target="_blank"
                        >
                          Datenschutzerklärung
                        </a>{" "}
                        zur Kenntnis genommen. Ich stimme zu, dass meine Angaben
                        und Daten zur Beantwortung meiner Anfrage erhoben und
                        gespeichert werden.
                      </label>
                    </div>
                    <div className="m-input-moment">
                      <button
                        type="submit"
                        className="im-btn btn-save ion-checkmark"
                      >
                        Senden
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    )
  }
}
export default Promo
