import React from "react"
import { MDBContainer, MDBRow } from "mdbreact"
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Promo from "./Promo"
import Exeperties from "./experties"
import Arrow from "../../resources/images/arrow.svg"
const index = () => {
  return (
    <div className="home" id="top">
      <MDBContainer fluid>
        <MDBRow fluid>
          <Promo />
          <Exeperties />
          <MDBContainer>
            <MDBRow>
              <div className="embed-container">
                <iframe
                  src="https://player.vimeo.com/video/539541592"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            </MDBRow>
          </MDBContainer>
          <AnchorLink href='#top' className="arrow">
            <div className="box">
              <img src={Arrow} />
            </div>
          </AnchorLink>
        </MDBRow>
      </MDBContainer>
    </div>
  )
}

export default index
