import React from "react"
import { MDBContainer, MDBRow, MDBCol, MDBTypography } from "mdbreact"

import Expo1 from "../../resources/images/1.png"
import Expo2 from "../../resources/images/2.png"
import Expo3 from "../../resources/images/3.png"
import Expo4 from "../../resources/images/4.png"

const experties = () => {
  return (
    <div className="unsere">
      <MDBContainer>
        <MDBRow>
          <MDBCol size="12">
            <MDBTypography tag="h1">Unsere Expertise</MDBTypography>
            <MDBTypography tag="p">
              Vicreation unterstützt Sie in verschiedenen Bereichen für das
              besondere Kundenerlebnis.
            </MDBTypography>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="3" sm="6" xs="12">
            <div className="box">
              <div className="num">
                <MDBTypography tag="p">1</MDBTypography>
              </div>
              <img src={Expo1} />
              <div className="text">
                <MDBTypography tag="h3">Web Design & Development</MDBTypography>
                <MDBTypography tag="p">
                  Unsere Websites haben nicht nur ein einzigartiges Design - sie
                  haben auch eine überragende Performance!
                </MDBTypography>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="3" sm="6" xs="12">
            <div className="box">
              <div className="num">
                <MDBTypography tag="p">2</MDBTypography>
              </div>
              <img src={Expo2} />
              <div className="text">
                <MDBTypography tag="h3">Video & Animation</MDBTypography>
                <MDBTypography tag="p">
                Egal ob emotional oder informativ, unsere Produkte beeindrucken, denn - VI create anything you want!
                </MDBTypography>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="3" sm="6" xs="12">
            <div className="box">
              <div className="num">
                <MDBTypography tag="p">3</MDBTypography>
              </div>
              <img src={Expo3} />
              <div className="text">
                <MDBTypography tag="h3">User Experience Consultancy</MDBTypography>
                <MDBTypography tag="p">
                Mit uns erzielen Sie Ihr gewünschtes Ergebnis - Das begeistert nicht nur Sie, sondern auch Ihren Kunden!
                </MDBTypography>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="3" sm="6" xs="12">
            <div className="box">
              <div className="num">
                <MDBTypography tag="p">4</MDBTypography>
              </div>
              <img src={Expo4} />
              <div className="text">
                <MDBTypography tag="h3">Corporate
Identity</MDBTypography>
                <MDBTypography tag="p">
                Persönliche, individuelle und qualitativ hochwertige Produkte, die die Seele Ihres Unternehmens bestens in Szene setzen.
                </MDBTypography>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  )
}

export default experties
